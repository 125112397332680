'use client';
import React, {useEffect, useState} from 'react';
import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {useGSAP} from '@gsap/react';
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(useGSAP, ScrollTrigger, MotionPathPlugin);

function LoadingScreen() {
    // useEffect(() => {
    //   if (typeof gsap !== 'undefined') {
    //     gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    //
    //     ScrollTrigger.normalizeScroll(true);
    //
    //     // create the smooth scroller FIRST!
    //     let smoother = ScrollSmoother.create({
    //       smooth: 2,
    //       effects: true,
    //     });
    //   }
    // }, []);
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         if (typeof gsap !== 'undefined') {
    //             clearInterval(interval);
    //             const svg = document.getElementById('svg');
    //             const tl = gsap.timeline();
    //             const curve = 'M0 502S175 272 500 272s500 230 500 230V0H0Z';
    //             const flat = 'M0 2S175 1 500 1s500 1 500 1V0H0Z';
    //
    //             // Animate the progress circle by reducing strokeDashoffset
    //             tl.to('.progress-bar', {
    //                 duration: 1.5,
    //                 strokeDashoffset: 0, // Animate to full circle (0 dashoffset)
    //                 ease: 'power2.easeOut',
    //             });
    //             tl.to(svg, {
    //                 duration: 0.5,
    //                 attr: {d: curve},
    //                 ease: 'power2.easeIn',
    //             }).to(svg, {
    //                 duration: 0.5,
    //                 attr: {d: flat},
    //                 ease: 'power2.easeOut',
    //             });
    //             tl.to('.loader-wrap', {y: -1500});
    //             tl.to('.loader-wrap', {zIndex: -1, display: 'none'});
    //             tl.from('header', {y: 200}, '-=1.5');
    //             tl.from(
    //                 'header .container',
    //                 {y: 40, opacity: 0, delay: 0.3},
    //                 '-=1.5'
    //             );
    //         }
    //     }, 100);
    // }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            if (typeof gsap !== 'undefined') {
                clearInterval(interval);
                const svg = document.getElementById('svg');
                const tl = gsap.timeline();
                const curve = 'M0 502S175 272 500 272s500 230 500 230V0H0Z';
                const flat = 'M0 2S175 1 500 1s500 1 500 1V0H0Z';

                // Animate the progress circle
                tl.to('.progress-bar', {
                    duration: 0.5,
                    strokeDashoffset: 0, // Animate to full circle (0 dashoffset)
                    ease: 'power2.easeOut',
                });

                // Animate the progress circle and logo to fade out and scale down
                tl.to('.progress-circle-wrap', {
                        duration: 0.5,
                        opacity: 0,
                        y: -400, // Moves the element up by 100px
                        ease: 'power2.easeOut',
                        delay: 1, // Wait for 1 second before starting the animation
                    },
                    '-=0.5' // Overlap with SVG animation
                );

                // Animate the SVG shape changes
                tl.to(svg, {
                    duration: 0.5,
                    attr: { d: curve },
                    ease: 'power2.easeIn',
                }).to(svg, {
                    duration: 0.5,
                    attr: { d: flat },
                    ease: 'power2.easeOut',
                });

                // Move loader-wrap off-screen and hide it
                tl.to('.loader-wrap', { y: -1500, duration: 0.8 }, '-=0.5'); // Align with the progress-circle animation
                tl.to('.loader-wrap', { zIndex: -1, display: 'none' });

                // Animate the header and main content after loader finishes
                tl.from('header', { y: 200 }, '-=1.5');
                tl.from(
                    'header .container',
                    { y: 40, opacity: 0, delay: 0.3 },
                    '-=1.5'
                );
            }
        }, 100);
    }, []);


    // Function to update the loading progress
    const [loadingProgress, setLoadingProgress] = useState(0);
        let progress = 0;
        const interval = setInterval(() => {
            // Simulating page load progress (you can replace this with actual loading progress from an API or other data)
            if (progress < 100) {
                progress += 2;  // Simulate progress increase
                setLoadingProgress(progress);
            } else {
                clearInterval(interval);  // Stop the interval once loading is complete
            }
        }, 100);  // Adjust interval speed to control the progress rate


    useEffect(() => {
        function updateCircleRadius() {
            const circle = document.querySelector('.progress-circle');
            const screenWidth = window.innerWidth;

            if (screenWidth < 425) {
                circle.setAttribute('r', '25'); // Smaller radius for mobile
            } else {
                circle.setAttribute('r', '15'); // Larger radius for desktop
            }
        }

        // Run on page load and resize
        window.addEventListener('load', updateCircleRadius);
        window.addEventListener('resize', updateCircleRadius);

        // Run the function initially and on resize
        updateCircleRadius();
        window.addEventListener('resize', updateCircleRadius);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateCircleRadius);
        };
    }, []);

    useEffect(() => {
        const progressCircle = document.querySelector('.progress-circle');

        if (progressCircle) {
            gsap.to(progressCircle, {
                strokeDashoffset: (1 - loadingProgress / 100) * 283, // 283 is the circumference of the circle
                duration: 1.5,
                ease: 'power2.easeOut',
            });
        }
    }, [loadingProgress]); // Trigger this effect whenever loadingProgress changes

    return (
        <div className="loader-wrap">
            <svg viewBox="0 0 1000 1000" preserveAspectRatio="none">
                <path id="svg" d="M0,1005S175,995,500,995s500,5,500,5V0H0Z"></path>
            </svg>
            <div className="loader-wrap-heading">
                <div className="progress-circle-wrap">
                    <svg
                        className="progress-circle"
                        width="20"
                        height="20"
                        viewBox="0 0 120 120"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <circle
                            className="progress-background"
                            cx="50%"
                            cy="50%"
                            r="15"
                            stroke="#ccc"
                            strokeWidth="0.1"
                            fill="none"
                        />
                        <circle
                            className="progress-bar"
                            cx="50%"
                            cy="50%"
                            r="15"
                            stroke="#fff"
                            strokeWidth="0.1"
                            fill="none"
                            strokeDasharray="94.2"
                            strokeDashoffset="94.2"
                            transform="rotate(0 60 60)"
                        />
                    </svg>
                    <img src="/assets/imgs/Orescha Logo White.png" className="fix-img" alt="Orescha Logo"/>
                </div>
            </div>
        </div>
    );
}

export default LoadingScreen;