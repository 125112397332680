import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/homepage.js';
import BlogClassic from './pages/blog-classic';
import BlogDetails from './pages/blog-details';
import Page404 from './pages/page-404.js';
import PageAbout from './pages/page-about.js';
import PageContact from './pages/page-contact.js';
import PageFAQ from './pages/page-FAQ.js';
import PageServices from './pages/page-services.js';
import PageServicesDetails from './pages/page-services-details.js';
import PortfolioCreative from './pages/portfolio-creative.js';
import ProjectDetails from './pages/project-details.js';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.config({ trialWarn: false });
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/blog" element={<BlogClassic />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/page-404" element={<Page404 />} />
        <Route path="/about-us" element={<PageAbout />} />
        <Route path="/contact-us" element={<PageContact />} />
        <Route path="/faq" element={<PageFAQ />} />
        <Route
          path="/services-details"
          element={<PageServicesDetails />}
        />
        <Route path="/services" element={<PageServices />} />
        <Route path="/work" element={<PortfolioCreative />} />
        <Route path="/project-details" element={<ProjectDetails />} />

        {/*        
        {/*
         */}
      </Routes>
    </Router>
  );
}

export default App;
