'use client';
import React, { useState, useEffect } from 'react';
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import { TextPlugin } from "gsap/TextPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(useGSAP, TextPlugin, ScrollTrigger);

function Header() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1200px)');
    const handleResize = () => setIsSmallScreen(mediaQuery.matches);

    // Initial check
    handleResize();

    // Add listener for screen changes
    mediaQuery.addEventListener('change', handleResize);

    // Cleanup listener on unmount
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  useEffect(() => {
    // Parallax background effect
    gsap.to(".main-header", {
      yPercent: -50, // Move the background upwards for a parallax effect
      ease: "none",
      scrollTrigger: {
        trigger: ".main-header",
        start: "top top", // When the header hits the top of the viewport
        end: "bottom top", // Until the bottom of the header hits the top of the viewport
        scrub: true, // Smooth scroll-based animation
      },
    });

    // Animate the caption on scroll
    gsap.from(".caption", {
      y: 50,
      opacity: 0,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".main-header",
        start: "top center", // Starts when the header reaches the center of the viewport
        toggleActions: "play none none none",
      },
    });

    // Animate the arrow down element
    gsap.from(".arrow-big", {
      y: 100,
      opacity: 0,
      duration: 1,
      ease: "power2.out",
      scrollTrigger: {
        trigger: ".main-header",
        start: "top bottom", // Starts animating when the bottom of the header enters the viewport
        toggleActions: "play none none none",
      },
    });
  }, []);

  // Animate text hero
  useEffect(() => {
    // GSAP Timeline
    const tl = gsap.timeline();

    // Animate the first h1 line
    tl.from(".first-line", {
      duration: 1,
      opacity: 0,
      y: 20,
      ease: "power2.out",
      delay: 1,
    }); // Slight overlap for smoother animation

    // Animate the rest of the text in the second h1 line
    tl.from(".second-line", {
      duration: 1,
      opacity: 0,
      y: 20,
      ease: "power2.out",
      delay: 1,
    });

    // Delay the "all" span animation
    tl.from(".nowrap .orange-text", {
      duration: 1,
      opacity: 0,
      y: -20,
      ease: "power2.out",
    });

    // Animate the sub-text after "all"
    tl.from(".sub-txt", {
      duration: 1,
      opacity: 0,
      y: 20,
      ease: "power2.out",
    });
  }, []);

  // Animate project number
  useEffect(() => {
    // GSAP Timeline for synchronization
    const tl = gsap.timeline();

    // Animate the project number alongside the caption
    tl.to(".animate-number", {
      innerText: 96,  // The final number
      duration: 3,    // Duration of the animation
      ease: "power2.out", // Easing function for smooth animation
      snap: { innerText: 1 }, // Ensure it counts in whole numbers
      delay: 2.5,
      onUpdate: function () {
        // Ensure the text updates smoothly in whole numbers
        const numberElement = document.querySelector('.animate-number');
        if (numberElement) {
          numberElement.innerText = Math.floor(numberElement.innerText);
        }
      },
    });
  }, []);

  // Animate down arrow
  useEffect(() => {
    const tl = gsap.timeline();

    // Animate the arrow-big element
    tl.fromTo(
        ".arrow-big",
        {
          y: -100, // Starting position (off-screen or above its initial position)
          opacity: 0,
          ease: "power2.out",
        },
        {
          y: 0,     // Ends at its initial position
          opacity: 1,
          duration: 1,
          ease: "power2.out",
          delay: 2,
        }
    );

    // Move it down 50px after it enters
    tl.to(".arrow-big", {
      y: 50,  // Moves down by 50px from the initial position
      duration: 1,
      ease: "power2.out",
    }, "+=0.5"); // Optional delay after the first animation
  }, []);

  return (
      <header
          className=" main-header bg-img valign"
          data-background="/assets/imgs/background/bg8.jpg"
          data-overlay-dark="8"
      >
        {/* Gradient Fade */}
        <div className="fade-overlay"></div>

        <div className="container ontop">
          <div className="row">
            <div className="col-lg-11">
              <div className="caption">
                <h1 id="first-line">Don't have to do it</h1>
                <div className={`d-flex ${isSmallScreen ? 'flex-column' : 'align-items-end'}`}>
                  <div>
                    <h1 className="nowrap second-line">
                      <span className="main-color font-bold orange-text">all</span> by yourself!
                    </h1>
                  </div>
                  <div>
                    <div className={`text position-relative ${isSmallScreen ? 'mt-30' : 'ml-30'}`}>
                      <p className="sub-txt">
                        We {' '}
                        <span className="text-light fw-600">shape</span>{' '}
                        brands and{' '}
                        <span className="text-light fw-600">craft</span>{' '}
                        digital solutions<br/>— so you don’t have to go it alone.

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-80">
            <div className="col-lg-6 order-md-2">
              <div className="icon-img">
                <img src="/assets/imgs/icon-img/arrow-down-big.png" className="arrow-big" alt=""/>
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-end order-md-1">
              <div className="info">
                <h2 className="mb-10"><span className="animate-number">0</span> +</h2>
                <h6>
                  Projects completed <br/>
                  <span className="main-color">successfully</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </header>
  );
}

export default Header;