'use client';
import React, { useEffect } from 'react';

function Navbar() {
  function handleScroll() {
    const bodyScroll = window.scrollY;
    const navbar = document.querySelector('.navbar');

    if (bodyScroll > 300) navbar.classList.add('nav-scroll');
    else navbar.classList.remove('nav-scroll');
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  function handleToggleNav() {
    const navbarCollapse = document.querySelector('.navbar .navbar-collapse');
    navbarCollapse.classList.toggle('show');
  }

  return (
      <nav className="navbar navbar-expand-lg bord blur">
        <div className="container o-hidden">
          <a className="logo logo-230" href="/">
            <img src="/assets/imgs/Orescha%20Logo%20White.png" alt="logo" />
          </a>

          <button
              className="navbar-toggler"
              type="button"
              aria-label="Toggle navigation"
              onClick={handleToggleNav}
          >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
          </button>

          <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/about-us" >
                  <span className="rolling-text">About Us</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/services">
                  <span className="rolling-text">Services</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/work">
                  <span className="rolling-text">Work</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/blog">
                  <span className="rolling-text">Blog</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/contact-us">
                  <span className="rolling-text">Contact Us</span>
                </a>
              </li>
            </ul>
          </div>

          <div className="contact-button">
            <a
                href="/contact-us"
                className="butn butn-lg butn-bg main-colorbg radius-50"
            >
              <span className="text">Let&apos;s contact</span>
            </a>
          </div>
        </div>
      </nav>
  );
}

export default Navbar;
