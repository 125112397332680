'use client';
import React from 'react';

function Services() {
    return (
        <section className="services-inline pt-50 pb-150">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="sec-head md-mb80">
                            <h6 className="sub-title font-bold main-color mt-2 mb-15">Our Services</h6>
                            <h2 className="font-regular">Do you want to <b className="font-bold">shape</b> a brand
                                or{" "}
                                <b className="font-bold">craft</b> a digital solution?</h2>
                            <a href="/services" className="butn-crev d-flex align-items-center mt-30">
                                <span className="hover-this">
                                  <span className="circle hover-anim">
                                    <i className="ti-arrow-top-right"></i>
                                  </span>
                                </span>
                                <span className="text-white">Learn more</span>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-7 ms-auto">
                        <div className="d-flex align-items-center">
                            <span className="sub-title font-bold main-color mb-10">Strategy</span>
                            <div className="ml-50 mb-12">
                                <a href="/services-details">Learn more <span
                                    className="ti-arrow-top-right ml-10"></span></a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="d-flex align-items-center">
                                <h3><span className="fw-200">Brand</span> Blueprint</h3>
                            </div>
                        </div>
                        <div className="item">
                            <div className="d-flex align-items-center">
                                <h3>Customer <span className="fw-200">Journey</span> Design</h3>
                            </div>
                        </div>
                        <div className="item">
                            <div className="d-flex align-items-center">
                                <h3><span className="fw-200">Market</span> Insights Lab</h3>
                            </div>
                        </div>
                        <div className="item">
                            <div className="d-flex align-items-center">
                                <h3><span className="fw-200">Sales</span> Growth Accelerator</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-sm-50 pt-md-50 pt-lg-5 mt-lg-5">
                    <div className="col-lg-5 ">
                        <div>
                            <div>
                                <div className="d-flex align-items-center">
                                    <span className="sub-title font-bold main-color mb-10">Production</span>
                                    <div className="ml-auto mb-12">
                                        <a href="/services-details">Learn more <span
                                            className="ti-arrow-top-right ml-10"></span></a>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Creative <span className="fw-200">Hub</span></h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3><span className="fw-200">Event</span> Crafting</h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Visual <span className="fw-200">Storytelling</span></h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Editing <span className="fw-200">Suite</span></h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3><span className="fw-200">3D</span> Design Lab</h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Print <span className="fw-200">Solutions</span> Studio</h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3><span className="fw-200">End-to-End</span> Development</h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Artificial Intelligence <span className="fw-200">Power</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 ms-auto pt-sm-50 pt-md-50 pt-lg-0">
                        <div>
                            <div>
                                <div className="d-flex align-items-center">
                                    <span className="sub-title font-bold main-color mb-10">Marketing</span>
                                    <div className="ml-auto mb-12">
                                        <a href="/services-details">Learn more <span
                                            className="ti-arrow-top-right ml-10"></span></a>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3><span className="fw-200">Strategic</span> Campaigns</h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Communication <span className="fw-200">Masterplans</span></h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Media <span className="fw-200">Blueprint</span></h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3><span className="fw-200">Influencer</span> Connections</h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Reputation <span className="fw-200">Building</span></h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Insightful <span className="fw-200">Analytics</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-lg-5 pt-sm-50 pt-md-50 pt-lg-5">
                    <div className="col-lg-6">
                        <div>
                            <div>
                                <div className="d-flex align-items-center">
                                    <span className="sub-title font-bold main-color mb-10">Design</span>
                                    <div className="ml-auto mb-12">
                                        <a href="/services-details">Learn more <span className="ti-arrow-top-right ml-10"></span></a>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3><span className="fw-200">Creative</span> Design</h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Brand <span className="fw-200">Identity</span></h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Packaging <span className="fw-200">Design</span></h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3><span className="fw-200">Print</span> Design</h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>Illustration <span className="fw-200">Services</span></h3>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="d-flex align-items-center">
                                        <h3>UI/UX <span className="fw-200">Design</span></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5 mx-auto pt-sm-50 pt-md-50 ">
                        <div>
                            <div className="sec-head mb-50">
                                <h6 className="sub-title main-color mb-15">What makes us unique?</h6>
                                <h3>
                                    We don’t just create<br/>– we craft stories that resonate,<br/>
                                    visuals that captivate,<br/>and experiences that inspire.
                                </h3>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="circle-blur">
                    <img src="/assets/imgs/patterns/blur1.png" alt=""/>
                </div>
        </section>
);
}

export default Services;
