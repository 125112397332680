'use client';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

function Clients() {
  const swiperOptions = {
    speed: 600,
    loop: true,
    slidesPerView: 5, // Default for larger screens
    spaceBetween: 40,
    centeredSlides: true,
    breakpoints: {
      // when window width is >= 320px (mobile devices)
      320: {
        slidesPerView: 2, // Only 2 itema on very small screens
        spaceBetween: 10,
        centeredSlides: false,
      },
      // when window width is >= 480px (small screens/tablets)
      480: {
        slidesPerView: 2, // Show 2 items
        spaceBetween: 10,
        centeredSlides: false,
      },
      // when window width is >= 640px (tablets)
      640: {
        slidesPerView: 3, // Show 2 items
        spaceBetween: 20,
        centeredSlides: true,
      },
      // when window width is >= 768px (larger tablets)
      768: {
        slidesPerView: 4, // Show 4 items
        spaceBetween: 30,
        centeredSlides: true,
      },
      // when window width is >= 1000px (desktop)
      1000: {
        slidesPerView: 5, // Show 5 items
        spaceBetween: 40,
        centeredSlides: true,
      },
    },
  };
  return (
    <section className="clients-carso section-padding">
      <div className="container">
        <div className="swiper5" data-carousel="swiper">
          <Swiper
            {...swiperOptions}
            id="content-carousel-container-unq-clients"
            className="swiper-container"
            data-swiper="container"
          >
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/iute%20credit.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/remax%20infinity%20+.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/tertigio.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/beautybyalba.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/brautstylisten.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/tandek.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/bbb.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="item">
              <div className="img icon-img-100">
                <img src="/assets/imgs/brands/koni.png" alt="" />
              </div>
            </div>
          </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/alpan.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/cryptodaily.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/dominusoft.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/boostmobile.al.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/dreammattress.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/cryptodaily.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="item">
                <div className="img icon-img-100">
                  <img src="/assets/imgs/brands/2faf.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="sec-bottom mt-50">
          <div className="main-bg client-label d-flex align-items-center">
            <h6 className="fz-14 font-white fw-400">
              More than <span className="fw-400"> 35+ companies</span>{" "}
              trusted us locally & worldwide
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Clients;
