import React, {useState, useEffect}  from 'react';

function Footer() {

  const [date , setDate] = useState();

  const getYear = () =>  setDate(new Date().getFullYear())


  useEffect(() => {
    getYear();
  }, [])

  return (
    <footer className="clean-footer crev">
      <div className="container pb-40 pt-40 ontop">
        <div className="row justify-content-between">
          <div className="col-lg-2">
            <div className="logo md-mb40">
              <img src="/assets/imgs/Orescha%20Logo%20White.png" alt="" />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="column md-mb50">
              <h6 className="sub-title mb-30">Contact</h6>
              <h6 className="p-color fw-400">
               <br /> Tirana, Albania
              </h6>
              <h6 className="mt-30 mb-15">
                <a href="#0">info@orescha.com</a>
              </h6>
              <a href="#0" className="underline">
                <span className="fz-22 main-color">+355 68 90 91 999</span>
              </a>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="column md-mb50">
              <h6 className="sub-title mb-30">Useful Links</h6>
              <ul className="rest fz-14 opacity-7">
                <li className="mb-15">
                  <a href="/about-us">About</a>
                </li>
                <li className="mb-15">
                  <a href="/services">Services</a>
                </li>
                <li className="mb-15">
                  <a href="/work">Work</a>
                </li>
                <li>
                  <a href="/contact-us">Contact</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="column subscribe-minimal">
              <h6 className="sub-title mb-30">Newsletter</h6>
              <div className="form-group mb-40">
                <input type="text" name="subscrib" placeholder="Your Email" />
                <button>
                  <span className="ti-location-arrow"></span>
                </button>
              </div>
              <ul className="rest social-icon d-flex align-items-center">
                <li className="hover-this cursor-pointer">
                  <a href="https://www.facebook.com/profile.php?id=61569165629013" className="hover-anim">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                </li>

                <li className="hover-this cursor-pointer ml-10">
                  <a href="https://www.instagram.com/oreschatechnologies/" className="hover-anim">
                    <i className="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="pt-30 pb-30 mt-80 bord-thin-top">
          <div className="text-center">
            <p className="fz-14">
              © {date} Orescha Technologies
            </p>
          </div>
        </div>
      </div>
      <div className="circle-blur">
        <img src="/assets/imgs/patterns/blur1.png" alt="" />
      </div>
    </footer>
  );
}

export default Footer;
